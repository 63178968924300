
*{
box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar{
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#white-gradient{
  height:25rem;
  /* z-index: -1; */
  width:25rem;
  /* background-color: #6f6d6d; */
  position:absolute;
  /* position:relative; */
  filter:blur(150px);
  /* top:20rem; */
  /* margin-top:10rem; */
  margin-left:-2rem;
  margin-top:-8rem;
}
/* Header */
#container{
background-color: black;
width:100%;
height:5rem;
/* position: fixed; */
/* z-index: 1; */
/* margin-top:-10rem; */
/* top:10rem; */
/* border:2px solid grey; */

}
#logo{

/* border:2px solid white; */ 
  height:3rem;
  /* width:6rem; */
  /* margin-top: 1rem; */

  margin-left:3rem;
  margin-top:1rem;
}
#navbar{
  margin-Left: 40rem;
  margin-top: -3rem;
  display: flex;
  width: 50%;
  /* border: 2px solid grey; */
  align-items: center;
  justify-content: space-evenly;


}
#contact{

  padding:0.5rem 1.2rem;
  background-color: #2c4dce;
  color:white;
  border:none;
  font-size: large;
  border-radius: 4px;
}
#contact:hover{

  transform: scale(1);
}
.font-larger{
  font-size: large;
  color: white;
}

/* Header */


/* FrontPage */

/* left_part */
#circlee{
height:4rem;
width:4rem;
background-color: orange;
border-radius: 50%;
margin-top:4rem;


margin-left:21.3rem;
/* z-index:-1; */
} 



#left_part{
  /* margin-top:2rem; */
  /* border:2px solid rgb(240, 236, 236); */
/* margin-top:1rem; */
width:50%;
  /* border:2px solid white; */
  /* background-color: rgb(21, 26, 168); */
padding-top:0.1px;
}


#title{
  color: white;
  
  z-index:-1;
  /* border:2px solid white; */
  margin-top:-3rem;
  font-size: 4rem;
  font-weight: bold;
  margin-left:7rem;
  line-height: 1;
  word-spacing: 0.5;
  letter-spacing: 0.2px;
  
}



/**//**//**//**//**//**/

#para{
  color: #848183;
  margin-top:3rem;
  margin-left:7rem
}



/**//**//**//**//**/
#search{
  border:2px solid #cdcdcd;
  /* padding :1rem; */
  margin-top:3rem;
  margin-left:7rem;
  width:66%;
  height:4rem;
  background-color: white;
}

#location_icon{
  /* border:2px solid rgb(20, 19, 19); */
  margin-top:15px;
  /* color:white; */
  color:#2c4dce;
  /* height:2rem;
  width:1rem; */
  font-size: 2rem;
  margin-left:1rem;
}
#input{
  /* margin-top:-1rem; */
  position:relative;
top:-0.7rem;
  border:2px solid white;
  margin-left:2rem;
}
#input:focus{
 outline:none;
}
#leftpart_input_searchbutton{
height:2.8rem;
position:relative;
top:-0.7rem;
left:1rem;
margin-left:1.5rem;
width:6.8rem;
cursor: pointer;
border:none;
background-color: #2c4dce;
color:white;
border-radius: 8px;
border:2px solid white;
font-weight: bold;
}


/**//**/

#features{
  color: white;
  margin-top:3rem;
  margin-left:7rem;
  /* border:2px solid grey; */
  width:22%;
  display: inline-block;
}
#number{
  font-size: xx-large;
  margin-left:0.6rem;
}
#plus_icon{
  color:orange;
  position:relative;
  top:-2.2rem;
  font-size: xx-large;
  left:5.5rem;
}

#label{
  margin-top:-2rem;
  color:#848183;
  word-spacing: 3;
}
/* left_part */
/* left_part */
/* left_part */




/*right part*/
/*right part*/
/*right part*/

#rpcontainer{
  height:35rem;
  width:32rem;
  /* border:2px solid grey; */
  position:relative;
  top:-34rem;
  left:42rem;
  
}

#rpimage{
  height:100%;
  width:100%;
  border-top-left-radius:20rem;
  border-top-right-radius:20rem;
  border:4px solid #302e2d;

}



/*right part*/
/*right part*/
/*right part*/





/* FrontPage */



/* brand  */
/* brand  */
/* brand  */

#bcontainer{
  display: flex;
  justify-content: space-between;
  margin-top:0.6rem;
}


.bimage{
  height:9rem;
  width: 9rem;
  padding:1rem;
}



/* brand  */
/* brand  */
/* brand  */


/* Residencies */
/* Residencies */
/* Residencies */

#rcontainer{
  height:40rem;
  /* border:2px solid blue; */
  margin-top:3rem;
}

#rbest{
  font-size: 1.8rem;
  color: #ffa500;
  font-weight: bold;
  margin-left:3rem;
 
}
#rpopular{
font-size:2.5rem;
font-weight: bolder;color:#1f3e72;
margin-left:3rem;


}
/* #ffa500 */


#rright{
position: relative;
/* border: 2px solid grey; */
border-radius:5px;
width:2.5rem;
left:70rem;
height:2.5rem;
background-color: #eeeeff;
cursor: pointer;
top:-1rem;
}

#rleft{
position: relative;
width:2.5rem;
height:2.5rem;
border-radius:5px;
/* border: 2px solid grey; */
box-shadow: 0px 0px 4px 0.1px grey;
top:-3.5rem;
left:74rem;
cursor: pointer;
}


#sliderleft{
  color:blue;
  margin-left:6px;
  margin-top:6px;
}
#sliderright{
  color:blue;
  margin-left:8px;
  margin-top:6.9px;
  
}


#rscrollcontainer{
 margin-top:-1rem;
  /* border:2px solid blue; */
  /* height:26rem; */
 /* white-space: nowrap; */
 overflow-x:auto ;
display: flex;
margin-left:4rem;
gap:4rem;
margin-right:4rem;
}


#rscrollcontainer::-webkit-scrollbar {
  display: none; 
}




#part{
  display: inline-block;
  
}
#partimage{
  height:11rem;
  width:15rem;
}
#partprice{
  margin-top:10px;
  color:#8c8b8b;
font-size: larger;
}

#partname{

margin-top:0.5rem;
font-size: 2rem;
color:#1f3e72;
font-weight: bolder;
}
#partdetail{


  /* height:6rem; */
  width:15rem;
  /* border: 2px solid rgb(25, 24, 24); */
 color:#8c8b8b;
 margin-top:1rem;
 font-size: 0.8rem;

}

/* Residencies */
/* Residencies */
/* Residencies */



/* value */
/* value */
/* value */

#LPFAQcontainer{
  /* border:2px solid grey; */
height:35rem;
  width:30rem;
  margin-top:2rem;
  margin-left:1rem;
}

#LPFAQimage{
  height:100%;
  width:100%;
  border-top-left-radius: 49rem;
  border-top-right-radius: 49rem;
  border:9px solid #eaeaea;
}
#RPFAQcontainer{
  /* border:2px solid grey; */
  margin-left:40rem;
  margin-top:-37rem;
}
/* #FAQcontainer{
  margin-left:40rem;
} */
#RPFAQtitle1{
  font-size: 1.8rem;
  color: #ffa500;
  font-weight: bold;
margin-top:1rem;
}
#RPFAQtitle2{
  margin-top:1rem;
  font-size:2.5rem;
  font-weight: bolder;
  color:#1f3e72;
}
#RPFAQdesc{
  margin-top:1rem;
  color:#939492;

}



#FAQcontainer{
  margin-top:3rem;
}
#FAQPartcontainer{
/* height:0rem; */
width:37rem;
cursor: pointer;
border:1px solid rgb(218, 217, 217);
margin-top:1rem;
/* height:1rem; */
/* height:5rem; */
/* box-shadow:0px 0px 2px 0.0px grey; */

}

#FAQParttitle{
  /* border:2px solid grey; */
  text-align: center;
  
  font-size: larger;
  margin-top:-2.3rem;
  color: #1f3e72;
  font-weight: bolder;
  height:2rem;

}
#FAQPartdesc{

  /* border:2px solid grey; */
 margin-top:-1rem;
 /* text-align: center; */
 padding:1rem;
  color:#939492;
  cursor:text;
}
#FAQIcon{

  height:2.3rem;
  width:2.3rem;
  background-color: #eeeeff;
  margin-left:1rem;
  margin-top:1rem;
}
#FAQIcondown{

  height:2.3rem;
  width:2.3rem;
  background-color: #eeeeff;
  position:relative;
  left:33rem;
  top:-2.0rem;
}

/* value */
/* value */
/* value */


/* contact */
/* contact */
/* contact */

#contactcontainer{
  margin-top:7rem;
/* border:2px solid grey; */
height:40rem;
width:100%;
}
#contactLPcontainer{
/* border:2px solid rgb(126, 21, 21); */
width:48%;
height:100%;
margin-left:2rem;
}

#contactLPtitle1{
  font-size: 1.5rem;
  color: #ffa500;
  font-weight: bold;


}

#contactLPtitle2{

 font-size:2.5rem;
font-weight: bolder;
color:#1f3e72;
}
#contactLPdesc{
  color:#939492;
  margin-top:1rem;
}
#contactLPcontacts{
  margin-top:5rem;
  height:23rem;
  width:100%;
  /* border:2px solid red; */
}

#contactLPicon{

/* border:2px solid grey; */
height:3rem;
width:3rem;
border-radius: 6px;
margin-left:1rem;
margin-top:1rem;
background-color: #eeeeff;
}
#call{
  margin-left:0.7rem;
  margin-top:0.7rem;
  color:#4066ff;
}
#contactLPheader{
font-size: large;
margin-top:-3rem;
margin-left:6rem;
color:#1f3e72;
font-weight: bolder;
}
#contactLPnumber{
margin-top:0rem;
margin-left:6rem;

/* color:#6c82d6 */


}
#contactLPnowbut{
/* border:2px solid grey; */
width:90%;
margin-left:0.8rem;
margin-top:2rem;
font-weight: bolder;
height:2.7rem;
color:#4066ff;
text-align: center;
padding-top:0.5rem;
/* padding:1rem; */
background-color: #eeeeff;
border-radius: 6px;

}



.gg{
height:9.8rem;
width:16.9rem;
margin-top:1rem;
margin-left:1rem;
border:0.5px solid #ededed;
display: inline-block;
}







#contactRPcontainer{
  /* border:2px solid rgb(184, 247, 12); */
width:38%;
height:90%;
margin-left:47rem;
margin-top:-39.7rem;
}
#contactRPimage{
  height:100%;
  width:100%;
  border-top-left-radius: 49rem;
  border-top-right-radius: 49rem;
}





/* contact */
/* contact */
/* contact */



/* subscribe */
/* subscribe */
/* subscribe */
  
#subscribecontainer{
  height:18rem;
  margin-top:3rem;
  width:95%;
  margin-left:2rem;
  background-color: #4161df;
  border:7px solid #5d77d6;
  border-radius:10px;
}

#subscribetitle{


  color:white;
  font-size: 2rem;
  text-align: center;
  font-weight:bolder;
padding:2rem;
}
#subscribedesc{

/* margin-top:3rem; */
text-align: center;
color:#b1bef2;
width:29rem;
margin-left:23rem;
/* border:2px solid black; */

}
#subscribebut{

width:8.5rem;
height:3rem;
padding-left:1.8rem;
padding-top:0.7rem;
color:white;
border:3px solid white;
margin-top:3rem;
background-color: #5a73d7;
margin-left:33rem;
border-radius:10px;
cursor: pointer;


}
/* subscribe */
/* subscribe */
/* subscribe */


/* footer */
/* footer */
/* footer */

#FooterLPcontainer{
  margin-top:4rem;
  margin-left:2rem;
height:10rem;
width:20rem;
}
#FooterLPimage{
  height:3rem;
  width:8rem;
}
#FooterLPdesc{
  margin-top:1rem;
  color:#5d7bd7;
  width:15rem;
}

#FooterRPcontainer{
  height:9rem;
  width:24rem;
  margin-left:52rem;
  margin-top:-10rem;
  /* border:2px solid grey; */
}
#FooterRPtitle{
  font-size:2rem;
  font-weight: bolder;
  color:#1f3e72;
}
#FooterRPlocation{
  margin-top:2px;
  color:#7286d7;
}

#FooterRPflexcontainer{
  margin-top:2rem;
  width:23rem;
  display: flex;
  /* border:2px solid grey; */
  justify-content: space-between;
}
.boldd{
  font-weight:600;
  font-size: large;
}


/* footer */
/* footer */
/* footer */